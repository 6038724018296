import React from 'react';

class Slideshow extends React.Component {

  state = { activeIndex: 0 }

  nextSlide = () => {
    clearInterval(this.intervalID);

    if (this.state.activeIndex === this.props.images.length - 1) {
      this.setState({
        activeIndex: 0
      })
    } else {
      this.setState({
        activeIndex: this.state.activeIndex + 1
      })
    }

    this.intervalID = setInterval(
      () => this.nextSlide(),
      3000
    );
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.nextSlide(),
      3000
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    let activeImage = this.props.images[this.state.activeIndex]

    return (
      <div className="slideshow">
        <button onClick={this.nextSlide}>
          <img src={activeImage.publicURL} alt={this.props.post.frontmatter.title} />
        </button>
      </div>
    )
  }
}

export default Slideshow
