import React from "react"
import Slideshow from "./slideshow"

class ExhibitSection extends React.Component {

  state = { projectOpen: false }

  toggleView = () => {
    this.setState({
      projectOpen: !this.state.projectOpen
    })
  }

  render() {
    const projectOpen = this.state.projectOpen ? "open" : "";

    return (
      <div className="project">
        <div className={`project-overlay ${projectOpen}`}>
          <button className="project-overlay-close" onClick={this.toggleView}>
            ✕
          </button>
          <div className="project-overlay-container">
            <div className="information">
              <h2>{this.props.post.frontmatter.name}</h2>
              <h3>{this.props.post.frontmatter.title}</h3>
              <div
                className="caption"
                dangerouslySetInnerHTML={{ __html: this.props.post.html }}
              />
            </div>
            <div className="media">
            {this.props.post.frontmatter.slideshow ? (
              <Slideshow post={this.props.post} images={this.props.post.frontmatter.images} />
            ) : ("")}
            {this.props.post.frontmatter.secondarySlideshow ? (
              <Slideshow post={this.props.post} images={this.props.post.frontmatter.secondarySlideshow} />
            ) : ("")}
            {this.props.post.frontmatter.video ? (
              <video controls>
                <source src={this.props.post.frontmatter.video.publicURL} type="video/mp4" />
              </video>
            ) : ("")}
            {this.props.post.frontmatter.audio ? (
              <audio controls>
                <source src={this.props.post.frontmatter.audio.publicURL} type="audio/mpeg" />
              </audio>
            ) : ("")}
            {this.props.post.frontmatter.additional ? (
              <img src={this.props.post.frontmatter.additional[0].publicURL} alt={this.props.post.frontmatter.name} />
            ) : ("")}
            {this.props.post.frontmatter.additional && this.props.post.frontmatter.additional[1] ? (
              <img src={this.props.post.frontmatter.additional[1].publicURL} alt={this.props.post.frontmatter.name} className="additional-image"/>
            ) : ("")}
            </div>
          </div>
        </div>
        <button className="hero" onClick={this.toggleView}>
          <img src={this.props.post.frontmatter.image.publicURL} alt={this.props.post.frontmatter.title} />
          <p><b>{this.props.post.frontmatter.name}</b>, {this.props.post.frontmatter.title}</p>
        </button>
      </div>
    )
  }
}

export default ExhibitSection
