import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header";
import Footer from "../components/footer"
import ExhibitSection from "../components/exhibitSection"

const ExhibitionPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.name)
    .map(edge => <ExhibitSection key={edge.node.id} post={edge.node} />)

  return (
    <div>
      <Header />
      <div className="content">
        <h1>Exhibition</h1>
        <div className="inner">
          <div className="section">
            <p>Freed from the constraints of traditional in-person visitation, we invite you to explore our online exhibition. From reporting to activism, crafting to education, our exhibition reflects the vast breadth and ever-evolving definition of Illustration Research Methods.</p>
          </div>
        </div>
        <div className="inner exhibition">
          {Posts}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default ExhibitionPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\//exhibition/"} },
      sort: { order: ASC, fields: [frontmatter___name] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            name
            title
            slideshow
            secondarySlideshow {
              publicURL
            }
            images {
              publicURL
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            additional {
              publicURL
            }
            video {
              publicURL
            }
            audio {
              publicURL
            }
          }
        }
      }
    }
  }
`
